import { defineStore } from 'pinia'
import router from '@/router'
import { useMenuStore } from './menu'
import { useDictStore } from './dict'
import { useTabStore } from './tab'
import { useRouteStore } from './route'
import { fetchProfile } from '@/service/base/common'

interface IUserinfo {
  id: number
  username: string
  [str: string]: any
}

export const useUserStore = defineStore('user', {
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'user-store',
        paths: ['accessToken', 'refreshToken', 'tokenExpires'],
      },
    ],
  },
  state: () => ({
    accessToken: '',
    refreshToken: '',
    tokenExpires: 0,
    isInit: false,
    userInfo: <IUserinfo>{},
    permissions: <string[]>[], // setAuthorities
  }),
  getters: {
    isLogin: state => {
      return !!state.accessToken
    },
  },
  actions: {
    async setToken(token: any) {
      this.accessToken = token.accessToken
      this.refreshToken = token.refreshToken
      this.tokenExpires = token.tokenExpires
    },
    setUserinfo(info?: IUserinfo) {
      if (info) {
        this.userInfo = info
      }
    },

    /**
     * 校验用户登录态
     */
    async fetchCheck() {},

    /**
     * 获取用户信息
     */
    async fetchUserInfo() {
      await fetchProfile().then(res => {
        this.permissions = res.data.permissions
        this.userInfo = res.data.userInfo
      })
    },

    /**
     * 初始化基础必要信息
     * userInfo、permissions、menus
     */
    async handlerInit() {
      let loading: any
      try {
        if (!this.isInit && this.isLogin) {
          loading = ElLoading.service({
            lock: true,
            text: '',
            background: '#ffffff',
          })
          this.isInit = true
          await Promise.all([this.fetchUserInfo(), useMenuStore().fetchMenus(), useDictStore().fetchDicts()])
        }
        return true
      } catch(err) {
        return false
      } finally {
        if(loading) {
          setTimeout(() => {
            loading.close()
          }, 500)
        }
      }
    },

    /**
     * 退出系统 做一个发布订阅
     */
    async handlerLogout() {
      this.setToken({})
      this.isInit = false
      useDictStore().handlerDestory()
      useMenuStore().handlerDestory()
      useTabStore().handlerDestory()

      router.replace({ path: useRouteStore().loginPagePath })
    },
  },
})
