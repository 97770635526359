import { request } from '@/service'

export function fetchLogin(params: { username: string, password: string }) {
  return request({
    method: 'post',
    url: '/admin/auth/login',
    data: params
  })
}

export function fetchProfile() {
  return request({
    method: 'get',
    url: '/admin/auth/profile',
  })
}

export function fetchMenu() {
  return request({
    method: 'get',
    url: '/admin/auth/menu',
  })
}

export function fetchDict() {
  return request({
    method: 'get',
    url: '/admin/dict/all',
  })
}

export function fetchUpload(file: File, progress?: (percentage: number, event?: ProgressEvent) => void){

  const formData = new FormData();
  formData.append("file", file)

  return request({
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress(progressEvent){
      if(progress) {
        const percent = progressEvent.loaded / progressEvent.total * 100
        progress(percent, progressEvent)
      }
    },
    url: '/admin/file/upload',
    data: formData
  })
}