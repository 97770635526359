import { defineStore } from 'pinia'
import router from '@/router'
import { useRouteStore } from './route'
import { listToTree, treeFindPath, treeFind } from '@/utils/treeUtil'
import { fetchMenu } from '@/service/base/common'
import { useSettingStore } from './setting'


export const useMenuStore = defineStore({
  id: 'menu',
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'combo-store-menu',
        paths: ['collapse'],
      },
    ],
  },
  state: () => ({
    menus: <any[]>[],
    collapse: false,
  }),
  getters: {
    menuTree(state) {
      const menus = toRaw(state.menus)
      const tree = listToTree(menus.filter(item => ['page', 'menu'].includes(item.type)))
      return tree
    },
    enabledMenuTree(){
      if(useSettingStore().showTopMenuNav) {
        return this.menuStack[0].children || []
      } else {
        return this.menuTree
      }
    },
    menuStack() {
      const currentRoutePath = router.currentRoute.value.path
      const path: Record<string, string>[] = []
      treeFindPath(
        this.menuTree,
        (node: any) => {
          if (node.path === currentRoutePath) {
            return true
          }
        },
        path,
      )
      return path
    },
    currentMenu(state) {
      return (path: string) => {
        const menu = state.menus.find(item => item.path === path)
        return menu
      }
    },
  },
  actions: {
    /**
     * 点击菜单导航
     */
    handlerNav(pageMenu: Record<string, string>){
      if(pageMenu.component) {
        router.push({ path: pageMenu.path })
      } else {
        window.open(pageMenu.path, pageMenu.target)
      }
    },
    /**
     * 是页面直接跳转，是菜单找最近的页面再跳转
     */
    navTo(path: string) {
      const menu = this.currentMenu(path)
      console.log(menu)
      if (menu?.type === 'page') {
        this.handlerNav(menu)
      } else if(menu?.type === 'menu') {
        // if(useRouteStore().enabledIndexPage) {
        //   router.push({path: useRouteStore().indexPagePath})
        // } else  {
         
        // }
        let pageMenu = treeFind([menu], (node: any) => {
          if(node.type == 'page') {
            return true
          }
        })
        if(pageMenu) {
          this.handlerNav(pageMenu)
        }
      }
    },
    /**
     * 设置菜单、路由
     */
    setMenus(menus: any[]) {
      this.menus = menus
      useRouteStore().setRoutes(this.menus)
    },

    /**
     * 菜单收起展开
     */
    setCollapse(flag: boolean) {
      this.collapse = flag
    },

    // 切换侧边栏导航展开/收起
    // toggleSidebarCollapse() {
    //   this.menu.subMenuCollapse = !this.menu.subMenuCollapse
    //   if (this.mode == 'pc') {
    //     this.subMenuCollapseLastStatus = !this.subMenuCollapseLastStatus
    //   }
    // },
    /**
     * 获取菜单并构建动态路由
     * 远程菜单 和 router/routes/dynamic
     */
    async fetchMenus() {
      await fetchMenu().then((res) => {
        this.setMenus(res.data)
      })
    },

    /**
     * 清空数据
     */
    handlerDestory() {
      this.setMenus([])
    },
  },
})
