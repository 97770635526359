import { defineStore } from 'pinia'
import router, { generateRoutes } from '@/router'
import config from '@/config/setting'
import type { RouteLocationNormalized } from 'vue-router'

export const useRouteStore = defineStore('route', {
  state() {
    return {
      ingorePathList: [...config.ignorePath],
      loginPagePath: config.loginPagePath,
      indexPagePath: config.indexPagePath,
      enabledIndexPage: true,
      routes: [],
      removeRoutes: <(() => void)[]>[],
      keepAliveList: <string[]>[],
    }
  },
  getters: {
    isIgnorePath() {
      return (path: string) => {
        return this.ingorePathList.includes(path)
      }
    },
  },
  actions: {
    navigateDefault() {
      router.push({ path: this.indexPagePath })
    },
    /**
     * 通过 menuStore 中 menus 数据渲染
     */
    setRoutes(menus: any) {
      const routes = menus.filter((item: any) => item.type === 'page' && item.component)
      this.routes = routes
      generateRoutes(this.routes)
    },
    setRemoveRoutes(routes: (() => void)[]) {
      this.removeRoutes = routes
    },
    setKeepAliveList(list: string[]) {
      this.keepAliveList = list
    },
    addKeepAlive(name: string | string[]) {
      if (typeof name === 'string') {
        !this.keepAliveList.includes(name) && this.keepAliveList.push(name)
      } else {
        name.forEach(v => {
          v && !this.keepAliveList.includes(v) && this.keepAliveList.push(v)
        })
      }
    },
    removeKeepAlive(name: string | string[]) {
      if (typeof name === 'string') {
        this.keepAliveList = this.keepAliveList.filter(v => {
          return v !== name
        })
      } else {
        this.keepAliveList = this.keepAliveList.filter(v => {
          return !name.includes(v)
        })
      }
    },
    generateKeepAlive(to: RouteLocationNormalized, from: RouteLocationNormalized) {
      //  // 判断当前页面是否开启缓存，如果开启，则将当前页面的 name 信息存入 keep-alive 全局状态
      if (to.meta.isAlive) {
        const componentName = to.matched.at(-1)?.components.default.name
        if (componentName) {
          this.addKeepAlive(componentName)
        } else {
          console.warn('该页面开启了缓存但组件未设置组件名，会导致缓存失效，请检查;', to.meta.title, to.path)
        }
      }

      //  if (to.meta.cache) {
      //      let componentName = to.matched[to.matched.length - 1].components.default.name
      //      if (componentName) {
      //          keepAliveStore.add(componentName)
      //      } else {
      //          console.warn('该页面组件未设置组件名，会导致缓存失效，请检查')
      //      }
      //  }
      //  // 判断离开页面是否开启缓存，如果开启，则根据缓存规则判断是否需要清空 keep-alive 全局状态里离开页面的 name 信息
      //  if (from.meta.cache) {
      //      let componentName = from.matched[from.matched.length - 1].components.default.name
      //      // 通过 meta.cache 判断针对哪些页面进行缓存
      //      switch (typeof from.meta.cache) {
      //          case 'string':
      //              if (from.meta.cache != to.name) {
      //                  keepAliveStore.remove(componentName)
      //              }
      //              break
      //          case 'object':
      //              if (!from.meta.cache.includes(to.name)) {
      //                  keepAliveStore.remove(componentName)
      //              }
      //              break
      //      }
      //      // 如果进入的是 reload 页面，则也将离开页面的缓存清空
      //      if (to.name == 'reload') {
      //          keepAliveStore.remove(componentName)
      //      }
      //  }
    },
    handlerDestory() {
      this.setKeepAliveList([])
    },
  },
})
