import { addCollection } from '@iconify/vue'
import icons from '@/assets/constant/icons'

/**
  IconifyIcon
  @iconify/json 所有图标集
  @iconify-json/{prefix} 单个图标集
  @iconify-icons/{prefix} 单个图标

  // 使用
  // import { listIcons, Icon } from '@iconify/vue'
  // const icons = listIcons('provider', 'custom')
  // <Icon :icon="@provider:custom:name"></Icon>

  // import { icons as mdiIcons } from '@iconify-json/mdi'
  // console.log(mdiIcons)

  // html @provider:prefix:name
  // <iconify-icon icon="@custom:md:test"></iconify-icon>
  // addIcon('mdi:account-box', {
  //   body: '<path d="M6 17c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1H6m9-9a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3a3 3 0 0 1 3 3M3 5v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2z" fill="currentColor"/>',
  //   width: 24,
  //   height: 24,
  // });
 */
addCollection(icons, 'provider')
