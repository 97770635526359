import { createApp } from 'vue'
import store from './stores'
import router from './router'
import App from './App.vue'
import './plugin/icons'
import installComponents from './plugin/components'
import './index.css'
// import Monitor from './utils/monitoring/index'
// const monitor = new Monitor({ id: '123' })
const app = createApp(App)

// app.config.errorHandler = (err) => {
//   /* 处理错误 */
// }

// 显式地添加他们，供所有的 Vue 表达式使用
// app.config.globalProperties

// app.component('TodoDeleteButton', TodoDeleteButton)

// 指令：参数、修饰符；name、argument、modifiers、value
// 动态参数 v-dictio:[params]=""

app.use(store)
app.use(router)
app.use(installComponents, { a: 333 })
// app.use(monitor)
app.mount('#app')
