import { createRouter, createWebHashHistory, type RouteLocationNormalized, type RouteRecordRaw } from 'vue-router'
import { useSettingStore, useTabStore, useUserStore, useRouteStore } from '@/stores'
import generatedRoutes from 'virtual:generated-pages'
import { setupLayouts } from 'virtual:generated-layouts'

const enabledRoutes = generateEnabledRoutes(generatedRoutes)
const constantRoutes = setupLayouts(enabledRoutes.filter(item => item.meta?.constant))

console.log('enabledPages', enabledRoutes)
// console.log('constantRoutes: ', constantRoutes) 13514849129

/**
 * 创建路由
 */
const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes: constantRoutes,
})

/**
 * 路由拦截器
 * 1. 已登录
 *    初始化数据 字典数据 权限数据; 动态路由加载后需要重新定向
 *    跳转登录页重新跳转到首页
 *    跳转首页判断是否启用首页，未启用跳转到当前导航的第一个页面
 *    404时可能是动态加载
 *    其他继续
 * 2. 未登录
 *    是忽略路径则继续
 *    其他跳转到登录页面
 */
router.beforeEach(async (to: RouteLocationNormalized) => {
  const routeStore = useRouteStore()
  const userStore = useUserStore()
  const settingStore = useSettingStore()

  console.log(`navgator to ${to.meta.title} -- ${to.fullPath}`, userStore.isLogin, to)

  settingStore.setLoading(true)

  if (userStore.isLogin) {
    await userStore.handlerInit()

    if (to.path === routeStore.loginPagePath) {
      return { path: routeStore.indexPagePath }
    } else if (to.path == routeStore.indexPagePath && !routeStore.enabledIndexPage) {
      return { name: 'sidebarfirstpage' }
    } else if (to.name == 'NotFound' && router.getRoutes().find(item => item.path == to.path)) {
      return { path: to.fullPath }
    } else if (to.name == 'NotFound' && to.path == '/') {
      return { path: routeStore.indexPagePath }
    } else {
      return true
    }
  } else {
    if (routeStore.isIgnorePath(to.path)) {
      return true
    } else {
      return {
        path: routeStore.loginPagePath,
        query: {
          redirect: to.fullPath,
        },
      }
    }
  }
})

/**
 * 路由拦截器 - 后置路由
 * 1.关闭进度条
 * 2.设置页面 title
 * 3.设置 tab 页面标签
 * 4.keep-alive
 */
router.afterEach((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const settingStore = useSettingStore()
  const tabStore = useTabStore()
  const routeStore = useRouteStore()

  // console.log('navgator after:', to.meta.title)

  settingStore.setLoading(false)

  settingStore.setTitle(to.meta.title)

  // 设置 tab 标签页
  tabStore.setTab(to, from)

  routeStore.generateKeepAlive(to, from)
})

/**
 * 异常捕获
 */
router.onError((error: any, to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  console.error('route error: ', error)
})

/**
 * 动态路由
 * 1. 移除动态加载的路由
 * 2. 动态加载
 *    没有设置 component 则通过 name 在已有路由中找
 *    如果有同名路由, 发出警告, 本地配置的不能移除，但是会优先使用传入数据
 */
export function generateRoutes(menuRoutes: any[]) {
  const routeStore = useRouteStore()
  const dynamicRoutes: RouteRecordRaw[] = []
  routeStore.removeRoutes.forEach((item: () => void) => item())
  routeStore.setRemoveRoutes([])

  menuRoutes.forEach(menu => {
    const menuName = generateRouteName(menu.path)
    const component = enabledRoutes.find(item => item.path === menu.component)
    if (component) {
      dynamicRoutes.push({
        path: menu.path,
        name: menuName,
        component: component.component!,
        redirect: component.redirect,
        meta: {
          ...component.meta,
          title: menu.title,
          icon: menu.icon,
          layout: menu.pageLayout,
          tabBar: menu.tabBar,
          closeableTabBar: component.meta.closeableTabBar ?? true,
          keepAlive: menu.keepAlive,
          target: menu.target,
        },
      })
    } else {
      console.warn('菜单配置未找到一级组件', menu.component)
    }
  })

  const layoutRoutes = setupLayouts(dynamicRoutes)
  const removeRoutes: (() => void)[] = []
  
  // console.log('layoutRoutes', layoutRoutes)
  layoutRoutes.forEach(item => {
    removeRoutes.push(router.addRoute(item))
  })

  routeStore.setRemoveRoutes(removeRoutes)

  // console.log('currentRoutes', router.getRoutes())
}

/**
 * 路径应该是以 / 开头
 */
function generateRouteName(path: string) {
  const pathArr = path.split('/').slice(1)
  return pathArr.join('-')
}

/**
 * 过滤开启的路由
 */
function generateEnabledRoutes(routes: RouteRecordRaw[]) {
  const enabledPages: RouteRecordRaw[] = []
  function filterChildren(item: RouteRecordRaw) {
    if (item.children?.length) {
      item.children = item.children.filter(it => it.meta?.enabled === true)
      if (item.children.length) {
        item.children.forEach(it => {
          filterChildren(it)
        })
      } else {
        delete item.children
      }
    }
  }

  routes.forEach(item => {
    if (item.meta?.enabled === true) {
      enabledPages.push(item)
      filterChildren(item)
    }
  })

  return enabledPages
}

export default router
