import { defineStore } from 'pinia'
import config from '@/config/setting'

export const useSettingStore = defineStore('setting', {
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'combo-store-setting',
      },
    ],
  },
  state: () => {
    return {
      baseUrl: config.baseUrl,
      appName: config.title,
      appTitle: '',
      isLoading: false,
      layout: {
        enableMobileAdaptation: true,
      },
      showDebugBtn: false,
      showHelpBtn: false,
      showFullscreenBtn: true,
      showMessageBtn: false,
      showSettingBtn: false,
      showTab: true,
      showBreadcrumb: false,
      showTopMenu: false,
      showTopMenuNav: false,
      showAside: true,

      // 显示模式，支持：mobile、pc
      mode: 'pc',
      // 页面标题
    }
  },
  actions: {
    // 设置网页标题
    setTitle(title: string) {
      this.appTitle = title
    },

    // 设置Nprogress状态
    setLoading(flag: boolean) {
      this.isLoading = flag
    },
    // 设置访问模式
    setMode(width: number) {
      if (this.layout.enableMobileAdaptation) {
        // 先判断 UA 是否为移动端设备（手机&平板）
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          this.mode = 'mobile'
        } else {
          // 如果为桌面设备，再根据页面宽度判断是否需要切换为移动端展示
          if (width < 992) {
            this.mode = 'mobile'
          } else {
            this.mode = 'pc'
          }
        }
      } else {
        this.mode = 'pc'
      }
    },

    // 设置主题颜色模式
    // setColorScheme(color) {
    //   this.app.colorScheme = color
    // },
    // 更新主题配置
    // updateThemeSetting(data) {
    //   Object.assign(this, data)
    // },
    handlerDestory() {},
  },
})
