import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import Request from './request'
import config from '@/config/setting'
import { useUserStore } from '@/stores'

export interface IResponse<T> {
  code: number
  message: string
  data: T
}

const instance = new Request({
  baseURL: config.baseUrl,
  timeout: config.timeout,
  headers: {
    "Client-Code": config.clientCode,
    "Client-Secret": config.clientCode
    // "Tenant-Id": "",
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300
  },
  interceptors: {
    // 请求拦截
    requestInterceptors: config => {
      if(!config.headers) {
        config.headers = {}
      }
      if(useUserStore().accessToken) {
        config.headers.Authorization = "Bearer " + useUserStore().accessToken
      }
      
      return config
    },
    // 请求错误拦截
    requestInterceptorsCatch: error => {
      return Promise.reject(error);
    },
    // 响应拦截
    responseInterceptors: (res: any) => {
      const responseData = res.data

      if(responseData.code == 1 ) {
        return res
      } else {
        // console.log(responseData)
        ElMessage.closeAll()
        ElMessage({
          message: responseData.msg,
          type: 'warning',
        })

        // 用户未登录或者异常后退出
        if(responseData.code === 42001 || responseData.code == 42000) {
          useUserStore().handlerLogout()
        }

        return Promise.reject(res);
      }
    },
    // 响应错误拦截器
    responseInterceptorsCatch: error => {
      // console.log(error);
      return Promise.reject(error);
    },
  },
})

/**
 * 重复请求
 * 路由离开取消请求
 */
export const request = <R = any>(config: AxiosRequestConfig) => {
  return instance.request<IResponse<R>>(config)
}
