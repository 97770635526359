import { defineStore } from 'pinia'
import { fetchDict } from '@/service/base/common'

export const useDictStore = defineStore('dict', {
  state() {
    return {
      dictList: [],
    }
  },
  getters: {
    findByKey() {
      return {}
    },
 
  },
  actions: {
    async fetchDicts() {
      await fetchDict().then(res => {
        this.dictList = res.data
      })
    },
    handlerDestory() {},
  },
})
