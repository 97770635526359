<template>
  <el-config-provider size="default" :zIndex="zIndex" :locale="zhCn">
    <RouterView />
  </el-config-provider>
</template>

<script setup lang="ts">
import { onBeforeUnmount } from 'vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { useSettingStore } from './stores'
import emitter from '@/plugin/event'

// let timerIndex = 1
// const timer = setInterval(() => {
//   timerIndex++
//   emitter.emit('timer',timerIndex)
// }, 1000)

// onBeforeUnmount(() => {
//   emitter.all.clear()
//   clearInterval(timer)
// })

NProgress.configure({
  showSpinner: false,
})
const settingStore = useSettingStore()
const zIndex = 2000

/**
 * 设置 title
 */
watchEffect(() => {
  let title = settingStore.appName
  if(settingStore.appTitle) {
    title = `${settingStore.appName}-${settingStore.appTitle}`
  }
  document.title = title
})

/**
 * NProgress 进度条
 */
watch(
  () => settingStore.isLoading,
  val => {
    if (val) {
      NProgress.start()
    } else {
      NProgress.done()
    }
  },
)

// const listener = window.addEventListener('resize', (event) => {
//   console.log(event)
// })
</script>
