const config = {
  /**
   * 高德地图web端 Key
   */
  amapKey: '8590441b668b5ec58a8921edaab94a0a',

  /**
   * 页面title
   * @type string
   */
  title: '赤峰地名志管理系统',

  /**
   * 页面icon
   */
  favicon: '',

  /**
   * 页面logo
   * @type string
   */
  logo: '赤峰地名志管理系统',

  /**
   * 页面关键字
   * @type string
   */
  keywords: '',

  /**
   * 页面描述
   * @type string
   */
  description: '',

  /*
   * 全局CDN css
   * @type String[]
   */
  link: [],

  /*
   * 全局CDN js
   * @type string[]
   */
  script: [],


  /**
   * 版权信息
   */
  copyright: '© 2009-2021 版权所有 创客呃呃',

  /**
   * 版权点击跳转URL
   */
  copyrightUrl: '',

  /**
   * http请求统一前缀，一般代理使用
   */
  baseUrl: '/api',

  /**
   * http请求超时时间
   */
  timeout: 30000,

  /**
   * 客户端id
   */
  clientCode: 'ADMIN',

  /**
   * 客户端密钥
   */
  clientSecret: "",

  /**
   * 登录页面
   */
  loginPagePath: '/login',

  /**
   * 首页
   */
  indexPagePath: '/dashboard',

  /**
   * 忽略权限校验的页面路径
   */
  ignorePath: ['/login', '/about', '/regist', '/find-password', '/login', '/demo'],

  /**
   * 不记录的在tag的页面名称
   */
  IgnoreTag: [],
}

export default config
