import { defineStore } from 'pinia'
import router from '@/router'
import type { RouteLocationNormalized } from 'vue-router'
import { useRouteStore } from '@/stores'

/**
 * tag标签
 */
export const useTabStore = defineStore({
  id: 'tab',
  persist: {
    enabled: true,
    strategies: [
      {
        key: 'combo-store-tab',
      },
    ],
  },
  state: () => ({
    tabCount: 1,
    tabs: <IAppTab[]>[],
  }),
  getters: {
    /**
     * 取最后加入的tab
     */
    nearestTab(store) {
      return store.tabs.reduce((prev, current) => {
        if (prev && current.index > prev.index) {
          return current
        }
        return prev
      }, null)
    },
  },
  actions: {
    /**
     * 对开启 tabBar 的页面添加到tabs中
     */
    setTab(to: RouteLocationNormalized, from: RouteLocationNormalized) {
      if (!to.meta.tabBar) return
      this.tabCount++
      // 如果页面已在tabs中,更新 index
      const tab = this.tabs.find(tab => tab.path === to.path)
      if (tab) {
        tab.index = this.tabCount
        return
      }

      // 获取添加位置
      let index = this.tabs.findIndex(tab => tab.path === from.path)
      if (index === -1) {
        index = this.tabs.length
      }

      // 添加tab
      this.tabs.splice(index + 1, 0, {
        index: this.tabCount,
        title: to.meta.title,
        path: to.path,
        icon: to.meta.icon,
        closable: to.meta.closeableTabBar ?? true,
      })
    },
    
    /**
     * 关闭标签页
     */
    removeTab(path: string) {
      const index = this.tabs.findIndex(tab => tab.path === path)
      if (index > -1) {
        this.tabs.splice(index, 1)
        // 如果关闭的是当前页面
        if (path === router.currentRoute.value.path) {
          if(this.nearestTab) {
            router.push({ path: this.nearestTab.path })
          } else {
            useRouteStore().navigateDefault()
          }
        }
      }
    },

    /**
     * 操作tab 关闭全部、关闭其他、关闭到右侧
     */
    operationTab(type: IAppTabOperation) {
      const currentRoutePath = router.currentRoute.value.path
      const currentIndex = this.tabs.findIndex(tab => tab.path === currentRoutePath)
      const hasCurrent = currentIndex > -1

      switch (type) {
        
        case 'other':  // 关闭当前和不能关闭以外的
          if (hasCurrent) {
            this.tabs = this.tabs.filter(item => item.closable === false || item.path === currentRoutePath)
          }
          break
        case 'all': // 关闭不能关闭以外的
          this.tabs = this.tabs.filter(item => item.closable === false)

          // 包含当前页面需要跳转页面
          if (hasCurrent) {
            if(this.nearestTab) {
              router.push({ path: this.nearestTab.path })
            } else {
              useRouteStore().navigateDefault()
            }
          }
          break
        case 'right':
          if (hasCurrent) {
            const removeTabs = this.tabs.splice(currentIndex + 1, this.tabs.length - currentIndex)
          }
          break
      }
    },

    handlerDestory() {
      this.tabs = []
    },
  },
})
