import axios, { type AxiosInstance, type AxiosResponse, type AxiosRequestConfig } from 'axios'

/**
 *  请求及响应拦截
 */
export interface RequestInterceptors {
  // 请求拦截
  requestInterceptors?: (config: AxiosRequestConfig) => AxiosRequestConfig
  // 请求拦截-异常
  requestInterceptorsCatch?: (err: any) => any
  // 响应拦截
  responseInterceptors?: <T = AxiosResponse>(config: T) => T
  // 响应拦截-异常
  responseInterceptorsCatch?: (err: any) => any
}

/**
 * 请求参数-扩展拦截器
 */
export interface RequestConfig extends AxiosRequestConfig {
  interceptors?: RequestInterceptors
}

/**
 * 取消token列表； 这里的key是不固定的，因为我们使用url做key，只有在使用的时候才知道url，所以这里使用这种语法。
 */
interface CancelRequestSource {
  [index: string]: () => void
}

/**
 * 实例请求拦截器 token
 * 全局请求拦截器 取消、缓存
 * 实例响应拦截器 
 * 全局响应拦截器 
 */
class Request {
  /**
   * axios 实例
   */
  instance: AxiosInstance

  /**
   * 实例拦截器对象
   */
  interceptorsObj?: RequestInterceptors

  /*
   * 存放所有请求URL的集合
   * 请求之前需要将url push到该集合中
   * 请求完毕后将url从集合中删除
   * 添加在发送请求之前完成，删除在响应之后删除
   */
  requestUrlList?: string[]

  /*
   * 存放取消方法的集合
   * 在创建请求后将取消请求方法 push 到该集合中
   * 封装一个方法，可以取消请求，传入 url: string|string[]
   * 在请求之前判断同一URL是否存在，如果存在就取消请求
   */
    cancelRequestSourceList?: CancelRequestSource[]

  /**
   * 构造函数
   */
  constructor(config: RequestConfig) {
    this.instance = axios.create(config)

    this.interceptorsObj = config.interceptors

    this.requestUrlList = []

    this.cancelRequestSourceList = []

    /**
     * 全局请求拦截器
     */
    this.instance.interceptors.request.use(
      (res: AxiosRequestConfig) => {
        // console.log('全局请求拦截器')
        return res
      },
      (err: any) => err,
    )

    // 使用实例拦截器
    this.instance.interceptors.request.use(this.interceptorsObj?.requestInterceptors, this.interceptorsObj?.requestInterceptorsCatch)
    this.instance.interceptors.response.use(this.interceptorsObj?.responseInterceptors, this.interceptorsObj?.responseInterceptorsCatch)

    /**
     * 全局响应拦截器
     */
    this.instance.interceptors.response.use(
      (res: AxiosResponse) => {
        return res
      },
      (error: any) => {
        console.log('response error', error)
        // 使用 toJSON 可以获取更多关于HTTP错误的信息
        // console.log(error.toJSON());
        // NProgress.done();
        // 判断请求是否被取消
        // if (axios.isCancel(error)) {
        //   console.log('Request canceled', thrown.message);
        // } 
        // 请求成功发出且服务器也响应了状态码，但状态代码超出了 validateStatus 的范围
        if (error.response) {
          console.log('response error', error)
        } else if (error.request) {
          // 请求已经成功发起，但没有收到响应; `error.request` 在浏览器中是 XMLHttpRequest 的实例，
          console.log('request error', error);
        } else {
          // 发送请求时出了点问题
          console.log('response Error', error.message);
        }
        // console.log(error.config);Promise.reject(error)
        // return error;
        return Promise.reject(error);
      },
    )
  }

  /**
   * 请求方法
   * url + params + data -> hash 请求标识，设置expire；把结果 并将结果保存到一个列表中 WeakMap 没有forEach
   * 在请求之前将url和取消请求方法push到我们前面定义的两个属性中
   * 在请求完毕后（不管是失败还是成功）都将其进行删除
   */
  request<R>(config: AxiosRequestConfig): Promise<R> {
    return new Promise((resolve, reject) => {
      /**
       * 请求标识；url存在保存取消请求方法和当前请求url
       * url + params + data -> hash
       * 
      // 从 v0.22.0 开始，Axios 支持以 fetch API 方式—— AbortController 取消请求
       * const controller = new AbortController();
        axios.get('/foo/bar', {
          signal: controller.signal
        }).then(function(response) {
          //...
        });
        // 取消请求
        controller.abort()
       * 
       */
      const url = config.url

      if (url) {
        this.requestUrlList?.push(url)
        config.cancelToken = new axios.CancelToken(c => {
          this.cancelRequestSourceList?.push({
            [url]: c,
          })
        })
      }

      const requestPromise = this.instance.request(config)

      requestPromise
        .then(res => {
          resolve(res.data)
        })
        .catch((err: any) => {
          reject(err)
        })
        .finally(() => {
          url && this.delUrl(url)
        })
    })
  }

  /**
   * 取消全部请求
   */
  cancelAllRequest() {
    this.cancelRequestSourceList?.forEach(source => {
      const key = Object.keys(source)[0]
      source[key]()
    })
  }

  /**
   * 取消请求；因为它可以取消一个和多个，那它的参数就是url，或者包含多个URL的数组，然后根据传值的不同去执行不同的操作
   * 取消单个请求
   * 存在多个需要取消请求的地址
   */
  cancelRequest(url: string | string[]) {
    if (typeof url === 'string') {
      const sourceIndex = this.getSourceIndex(url)
      sourceIndex >= 0 && this.cancelRequestSourceList?.[sourceIndex][url]()
    } else {
      url.forEach(u => {
        const sourceIndex = this.getSourceIndex(u)
        sourceIndex >= 0 && this.cancelRequestSourceList?.[sourceIndex][u]()
      })
    }
  }

  /**
   * 删除url和cancel方法
   * 删除 requestUrlList 和 cancelRequestSourceList
   */
  private delUrl(url: string) {
    const urlIndex = this.requestUrlList?.findIndex(u => u === url)
    const sourceIndex = this.getSourceIndex(url)
    urlIndex !== -1 && this.requestUrlList?.splice(urlIndex as number, 1)
    sourceIndex !== -1 && this.cancelRequestSourceList?.splice(sourceIndex as number, 1)
  }

  /**
   * 获取指定 url 在 cancelRequestSourceList 中的索引
   * @param {string} url
   * @returns {number} 索引位置
   */
  private getSourceIndex(url: string): number {
    return this.cancelRequestSourceList?.findIndex((item: CancelRequestSource) => {
      return Object.keys(item)[0] === url
    }) as number
  }
}

export default Request
