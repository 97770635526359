

import __layout_0 from '/D:/project/赤峰地名志/toponym-admin/src/layouts/default.vue'
export const layouts = {
'admin': () => import('/D:/project/赤峰地名志/toponym-admin/src/layouts/admin.vue'),
'data': () => import('/D:/project/赤峰地名志/toponym-admin/src/layouts/data.vue'),
'default': __layout_0,
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      component: layouts[route.meta?.layout || 'default'],
      children: [ {...route, path: ''} ],
    }
  })
}
